class ViewSwitch {
  constructor({ viewSwitchEl, views = {} }) {
    this.switchEl = viewSwitchEl;
    this.views = views;
    this._setZindex();
  }

  _setZindex() {
    Object.values(this.views).forEach((el, i) => (el.style.zIndex = i + 1));
  }

  mount(view) {
    this.views[view].classList.add("view--enter");
  }
}

export default ViewSwitch;
