import _ from "lodash";
import Inputmask from "inputmask";
import EventEmitter from "events/events.js";

import { isDev } from "./utils/helpers.js";
import formController from "./components/form";
import ProgressBar from "./components/ProgressBar";
import ViewSwitch from "./components/ViewSwitch.js";
import RadioQuestion from "./components/RadioQuestion";
import ThumbsQuestion from "./components/ThumbsQuestion";
import SliderQuestion from "./components/SliderQuestion.js";
import QuizController from "./components/QuizController.js";
import { DOB_REGEX, KLAVIYO_API_KEY } from "./utils/constants";
import { SHOWCASE_SLIDE_TEMPLATE } from "./utils/templates";
import FormControl from "./components/FormControl";

const { questions, beers } = window.YBF;

const ee = new EventEmitter();

const dobMask = new Inputmask({
  regex: DOB_REGEX,
});
dobMask.mask(".js-dob-mask");

/* -------------------------------------------------------------------------- */
/*                                  Elements                                  */
/* -------------------------------------------------------------------------- */
const prevBtnEl = document.querySelector("#prev-btn");
const nextBtnEl = document.querySelector("#next-btn");

const progressBarEl = document.querySelector(".progress-bar");

const viewSwitchEl = document.querySelector(".view-switch");
const quizView = document.querySelector("#quiz-view");
const leadView = document.querySelector("#lead-view");
const resultsView = document.querySelector("#results-view");

const formEl = document.querySelector("#form");

const questionTrackEl = document.querySelector("#question-track");

const loadScreen = document.querySelector("#load-screen");

const formControlEl = document.querySelector(".ybf-form-control");

/* -------------------------------------------------------------------------- */
/*                                 ProgressBar                                */
/* -------------------------------------------------------------------------- */
const progressBar = new ProgressBar({
  progressBarEl,
  levelCount: questions.length + 1, // add one for lead page
});
progressBar.init();

/* -------------------------------------------------------------------------- */
/*                                 View Switch                                */
/* -------------------------------------------------------------------------- */
const viewController = new ViewSwitch({
  viewSwitchEl,
  views: {
    quiz: quizView,
    lead: leadView,
    results: resultsView,
  },
});

const initSlickSlider = () => {
  $(".showcase__slider").slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: document.querySelector(".js-slider-left"),
    nextArrow: document.querySelector(".js-slider-right"),
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
};

// viewController.mount("lead"); // TEMP
// viewController.mount("results"); // TEMP
// initSlickSlider();

/* -------------------------------------------------------------------------- */
/*                                    Form                                    */
/* -------------------------------------------------------------------------- */

const form = formController({
  formEl,
  onResult: (res) => ee.emit("results", res),
  onSuccessfulSubmit: () => ee.emit("loading"),
});

/* -------------------------------------------------------------------------- */
/*                              Render Questions                              */
/* -------------------------------------------------------------------------- */
const questionObjs = questions.map((q) => {
  switch (q.type) {
    case "radio":
      let radioQuestion = new RadioQuestion(q);
      radioQuestion.onSelect(() => setTimeout(() => ee.emit("next"), 300));
      return radioQuestion;
    case "slider":
      return new SliderQuestion({ onButtonClick: () => ee.emit("next"), ...q });
    case "thumbs":
      let thumbsQuestion = new ThumbsQuestion(q);
      thumbsQuestion.onComplete(() => setTimeout(() => ee.emit("next"), 100));
      return thumbsQuestion;
    default:
      throw new Error("Unable to find question template for " + q.type);
  }
});

const questionController = new QuizController({
  container: questionTrackEl,
  questionObjs,
});
questionController.init();

/* -------------------------------------------------------------------------- */
/*                              Helper Functions                              */
/* -------------------------------------------------------------------------- */
const hideButton = (button) => button.classList.add("dir-button--hide");
const showButton = (button) => button.classList.remove("dir-button--hide");

const showLoadingScreen = () => loadScreen.classList.add("loading--show");
const hideLoadingScreen = () => loadScreen.classList.remove("loading--show");

const hydrateBeerRecommendation = ({
  name,
  hex,
  style,
  abv,
  brewery,
  location,
  description,
  image,
}) => {
  // beer name
  document.getElementById("rec-name").textContent = name;
  // style
  document.getElementById("rec-style").textContent = style;
  // abv
  document.getElementById("rec-abv").textContent = `${abv}%`;
  // brewery
  document.getElementById("rec-brewery").textContent = brewery;
  // location
  document.getElementById("rec-location").textContent = location;
  // description
  document.getElementById("rec-description").innerHTML = description;
  // image
  const imageEl = document.getElementById("rec-image");
  imageEl.src = image;
  imageEl.alt = name;
  // bubble color
  document.getElementById("rec-bubble").style.backgroundColor = hex;
};

const hydrateShowcaseSlider = () => {
  const sliderContainerEl = document.querySelector(".showcase__slider");
  const slides = beers.map((beer) => {
    const slideEl = SHOWCASE_SLIDE_TEMPLATE.cloneNode(true);
    slideEl.querySelector(".showcase__image").src = beer.image;
    slideEl.querySelector(".showcase__slider-title").textContent = beer.name;
    slideEl.querySelector(".showcase__bubble").style.backgroundColor = beer.hex;
    return slideEl;
  });
  sliderContainerEl.append(...slides);
};

const sendLeadToApi = async ({ beer, email }) => {
  const data = {
    token: KLAVIYO_API_KEY,
    event: "Completed Quiz",
    customer_properties: { $email: email },
    properties: { beer_name: beer },
  };

  const options = {
    method: "POST",
    headers: {
      Accept: "text/html",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      data: JSON.stringify(data),
    }),
  };

  return fetch("https://a.klaviyo.com/api/track", options)
    .then((response) => response.json())
    .then((response) => true)
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/* -------------------------------------------------------------------------- */
/*                               Event Listeners                              */
/* -------------------------------------------------------------------------- */
prevBtnEl.addEventListener("click", () => ee.emit("previous"));
nextBtnEl.addEventListener("click", () => ee.emit("next"));

/* -------------------------------------------------------------------------- */
/*                                   Events                                   */
/* -------------------------------------------------------------------------- */
ee.on("previous", () => {
  questionController.back();
  progressBar.setLevel(questionController.getCurrentQuestionIndex() + 1);
  if (questionController.getPreviousIndex() === false) {
    return hideButton(prevBtnEl);
  }
  showButton(nextBtnEl);
});

ee.on("next", () => {
  progressBar.setLevel(questionController.getCurrentQuestionIndex() + 1);
  if (questionController.getNextIndex() === false) {
    hideButton(nextBtnEl);
    hideButton(prevBtnEl);
    // init form control
    new FormControl(formControlEl);
    viewController.mount("lead");
    return form.unlock();
  }
  questionController.forward();
  showButton(prevBtnEl);
});

ee.on("loading", showLoadingScreen);

ee.on("results", async ({ beer, leadData }) => {
  progressBar.complete();
  // only send to api in production
  if (!isDev()) {
    await sendLeadToApi({ beer: beer.name, email: leadData.email });
  }
  hydrateBeerRecommendation(beer);
  hydrateShowcaseSlider();
  initSlickSlider();
  // relock form to prevent submission again
  form.lock();
  setTimeout(() => {
    viewController.mount("results");
    hideLoadingScreen();
  }, 1500);
});

/* -------------------------------------------------------------------------- */
/*                              Development Utils                             */
/* -------------------------------------------------------------------------- */
if (isDev()) {
  window.DEV = {
    showResults: () => {
      viewController.mount("lead");
      ee.emit("results", { beer: beers[0] });
    },
  };
}
