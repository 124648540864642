class ProgressBar {
  constructor({ progressBarEl, levelCount }) {
    this._progressBarEl = progressBarEl;
    this._levelCount = levelCount;

    this._bar = progressBarEl.querySelector(".progress-bar__bar");
  }

  init() {
    this.setLevel(1);
  }

  setLevel(level) {
    if (level === this._levelCount) {
      this._bar.classList.add("progress-bar__bar--complete");
    }
    const percentage = (level / this._levelCount) * 100;
    this._bar.style.width = percentage + "%";
  }

  complete() {
    this.setLevel(this._levelCount);
  }
}

export default ProgressBar;
