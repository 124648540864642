import _ from "lodash";
import dobToAge from "dob-to-age";
import validate from "validate.js";

// import beers from "../data/beers.json";
import { DOB_REGEX } from "../utils/constants";

const beers = window.YBF.beers;

class FormController {
  constructor({ formEl, onResult, onSuccessfulSubmit }) {
    this._formEl = formEl;
    this._onResult = onResult;
    this._onSuccessfulSubmit = onSuccessfulSubmit;

    this._formEl.addEventListener("submit", this._onSubmit.bind(this));

    this._errorMessageEls = this._formEl.querySelectorAll(
      ".lead__form .lead__form-input-error-message"
    );

    this._locked = true;

    // LINK add custom validator https://validatejs.org/#custom-validator
    validate.validators.age = (value, options, key, attributes) => {
      if (!value) return undefined;
      if (this._getAge(value) < options) {
        return `Sorry, you must be at least ${options} to join`;
      }
    };

    this._formConstraints = {
      dob: {
        presence: {
          message: "Please enter your date of birth",
        },
        format: {
          pattern: DOB_REGEX,
          message: "Please enter a valid date of birth",
        },
        age: 21,
      },
      email: {
        email: {
          message: "Please enter a valid email",
        },
      },
    };
  }

  unlock() {
    this._locked = false;
  }

  lock() {
    this._locked = true;
  }

  _onSubmit(e) {
    e.preventDefault();

    if (this._locked) return false;

    this._clearErrors();

    const formData = new FormData(this._formEl);

    const questionsMap = {};
    const leadData = {};

    // parse and organize data
    for (const pair of formData.entries()) {
      const [key, value] = pair;
      if (key.startsWith("question")) {
        if (!value) continue;
        // convert json to array and parse int
        // value = JSON.parse(value).map((i) => parseInt(i));
        const beers = JSON.parse(value);
        beers.forEach(({ beer_id: beerId, multiplier }) => {
          for (let i = 0; i < parseInt(multiplier); i++) {
            questionsMap[key] = !questionsMap[key]
              ? [beerId]
              : questionsMap[key].concat(beerId);
          }
        });
      } else {
        leadData[key] = value || null;
      }
    }

    // validate lead fields
    const validationErrors = validate(leadData, this._formConstraints, {
      fullMessages: false,
    });
    if (validationErrors) {
      return Object.entries(validationErrors).forEach(([inputName, message]) =>
        this._displayError(inputName, message)
      );
    }

    if (typeof this._onSuccessfulSubmit === "function") {
      this._onSuccessfulSubmit();
    }
    this._onResult({ beer: this._getRecommendedBeer(questionsMap), leadData });
  }

  _getRecommendedBeer(data) {
    console.log("beer data", data);
    // merge all arrays
    const recommendedBeers = Object.values(data).reduce(
      (arr, current) => arr.concat(current),
      []
    );

    // find most recommended among recommended
    const beerId = this._findMostOccuringNumber(
      recommendedBeers,
      recommendedBeers.length
    );

    return _.find(beers, ["id", beerId]);
  }

  /**
   * LINK https://www.geeksforgeeks.org/frequent-element-array/
   * @param {Array} arr
   * @returns {Number} most occuring number
   */
  _findMostOccuringNumber(arr, n) {
    arr.sort();

    // find the max frequency using linear
    // traversal
    let max_count = 1,
      res = arr[0];
    let curr_count = 1;

    for (let i = 1; i < n; i++) {
      if (arr[i] == arr[i - 1]) {
        curr_count++;
      } else {
        if (curr_count > max_count) {
          max_count = curr_count;
          res = arr[i - 1];
        }
        curr_count = 1;
      }
    }

    // If last element is most frequent
    if (curr_count > max_count) {
      max_count = curr_count;
      res = arr[n - 1];
    }
    return res;
  }

  _displayError(name, message) {
    _.find([...this._errorMessageEls], ["dataset.input", name]).textContent =
      message;
  }

  _clearErrors() {
    this._errorMessageEls.forEach((el) => (el.textContent = ""));
  }

  _getAge(dob) {
    // parse dob to acceptable format
    const [month, day, year] = dob.split("/");
    return dobToAge(`${year}-${month}-${day}`);
  }
}

export default function () {
  return new FormController(...arguments);
}
