/**
 * FormControl
 *
 * Add input animation to form labels.
 *
 */

class FormRow {
  constructor(rowEl) {
    this._inputEl = rowEl.querySelector("input, textarea");

    if (!this._inputEl) return;

    this._labelEl = rowEl.querySelector("label");

    this._init();

    this._setEventListeners();
  }

  _init() {
    if (this._inputEl.value !== "") {
      this._minimizeLabel();
    }
  }

  _setEventListeners() {
    this._inputEl.addEventListener("focus", this._minimizeLabel.bind(this));

    this._inputEl.addEventListener("focusout", (e) => {
      if (this._inputEl.value === "") {
        this._unminimizeLabel();
      }
    });
  }

  _minimizeLabel() {
    this._labelEl.classList.add("focused");
  }

  _unminimizeLabel() {
    this._labelEl.classList.remove("focused");
  }
}

class FormControl {
  constructor(container) {
    this.container = container;

    // find all gfields
    this._inputRowContainers = this.container.querySelectorAll(
      ".gfield, .ybf-form-control__input-container"
    );

    this._instantiateRows();
  }

  _instantiateRows() {
    this._rows = [];

    this._inputRowContainers.forEach((rowEl) => {
      this._rows.push(new FormRow(rowEl));
    });
  }
}

export default FormControl;
