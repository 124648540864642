import _ from "lodash";

import { SLIDER_QUESTION_TEMPLATE } from "../utils/templates.js";
import Question from "./Question.js";

class SlideQuestion extends Question {
  constructor({ onButtonClick, ...question }) {
    super(question);

    this._value = 0;

    this._onButtonClick = onButtonClick;

    this._step = 100 / (this._options.length - 1);
  }

  _mapValueToOption(value) {
    const index = Math.floor(value / this._step);
    return { index, ...this._options[index] };
  }

  _handleFillAnimation({ target }) {
    const min = target.min;
    const max = target.max;
    const val = target.value;

    target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
    console.log(target);
  }

  /**
   * Hydrate radio question template
   */
  getView() {
    const questionEl = SLIDER_QUESTION_TEMPLATE.cloneNode(true);

    // populate question text
    questionEl.querySelector(".question__heading").innerHTML = this._question;

    // add custom class to question container
    questionEl.classList.add(`question--id--${this._id}`);

    const sliderInputEl = questionEl.querySelector('input[type="range"]');
    const hiddenInputEl = questionEl.querySelector('input[type="hidden"]');
    const imageContainerEL = questionEl.querySelector(
      ".question__slider-image-container"
    );
    const valueDisplayEl = questionEl.querySelector(
      ".question__slider-value-display"
    );
    const nextButton = questionEl.querySelector(".question__slider-button");

    this._sliderInputEl = sliderInputEl;
    this._nextButton = nextButton;
    this._hiddenInputEl = hiddenInputEl;
    this._valueDisplayEl = valueDisplayEl;
    this._imageContainerEl = imageContainerEL;

    hiddenInputEl.name = this._inputsName;

    // set steps by option count
    sliderInputEl.step = this._step;

    // set event listeners
    sliderInputEl.addEventListener("input", this._handleInput.bind(this));
    sliderInputEl.addEventListener("input", this._handleFillAnimation);
    if (this._onButtonClick) {
      nextButton.addEventListener("click", this._onButtonClick.bind(this));
    }

    // render images inside container
    this._options.forEach(({ image, text }, i) => {
      const imageEl = document.createElement("img");
      imageEl.src = image;
      imageEl.className = `question__slider-image question__slider-image--i--${i}`;
      imageEl.alt = text;
      imageEl.title = text;
      imageContainerEL.append(imageEl);
    });

    // init value, text and image to first option
    this._updateOption({ index: 0, ...this._options[0] });

    this._element = questionEl;

    return this._element;
  }

  _handleInput(e) {
    const option = this._mapValueToOption(e.target.value);
    this._updateOption(option);
  }

  _updateOption({ text, beers, index }) {
    // change value text
    this._valueDisplayEl.textContent = text;
    // set hidden input value
    this._hiddenInputEl.value = JSON.stringify(beers);
    // change image
    const currentImage = this._imageContainerEl.querySelector(
      `.question__slider-image--active`
    );
    if (currentImage) {
      currentImage.classList.remove("question__slider-image--active");
    }
    const nextImage = this._imageContainerEl.querySelector(
      `.question__slider-image--i--${index}`
    );
    nextImage.classList.add("question__slider-image--active");
  }
}

export default SlideQuestion;
