import {
  THUMBS_QUESTION_TEMPLATE,
  THUMBS_OPTION_TEMPLATE,
} from "../utils/templates.js";
import Question from "./Question.js";

class ThumbsQuestion extends Question {
  constructor(question) {
    super(question);

    this._likeButton = null;
    this._dislikeButton = null;
    this._cardContainerEl = null;
    this._hiddenInput = null;

    this._value = [];
  }

  onComplete(fn) {
    this._onComplete = fn;
  }

  _setEventListeners() {
    this._likeButton.addEventListener("click", this._handleLikeBtn.bind(this));
    this._dislikeButton.addEventListener(
      "click",
      this._handleDislikeBtn.bind(this)
    );
  }

  _handleLikeBtn() {
    const activeCard = this._getActiveCard();
    this._updateValue(activeCard.dataset.beers);
    this._passCard(activeCard, true);
  }

  _handleDislikeBtn() {
    this._passCard(this._getActiveCard(), false);
  }

  _updateValue(newValue) {
    this._value = this._value.concat(newValue.split(","));
    this._hiddenInput.value = JSON.stringify(this._value);
  }

  _passCard(card, like = true) {
    if (like) {
      card.classList.add("question__thumbs-card--liked");
    }
    card.classList.add("question__thumbs-card--disliked");
    setTimeout(() => {
      // check if last card
      if (!card.nextSibling) {
        this._onComplete.call(this);
      }
      card.remove();
    }, 200);
  }

  _getActiveCard() {
    return this._cardContainerEl.firstElementChild;
  }

  getView() {
    const questionEl = THUMBS_QUESTION_TEMPLATE.cloneNode(true);

    this._likeButton = questionEl.querySelector(".question__thumbs-button--up");
    this._dislikeButton = questionEl.querySelector(
      ".question__thumbs-button--down"
    );

    this._hiddenInput = questionEl.querySelector(".question__thumbs-input");
    // set name
    this._hiddenInput.name = this._inputsName;

    // populate question text
    questionEl.querySelector(".question__heading").innerHTML = this._question;

    const cardContainerEl = questionEl.querySelector(
      ".question__thumbs-card-container"
    );

    this._cardContainerEl = cardContainerEl;

    // populate images
    this._options.forEach(({ text, image, beers }) => {
      const optionEl = THUMBS_OPTION_TEMPLATE.cloneNode(true);

      optionEl.dataset.beers = beers;

      optionEl.querySelector(".question__thumbs-card-title").textContent = text;
      optionEl.querySelector(".question__thumbs-card-image").src = image;

      cardContainerEl.append(optionEl);
    });

    this._element = questionEl;

    this._setEventListeners();

    return this._element;
  }
}

export default ThumbsQuestion;
