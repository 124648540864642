import _ from "lodash";

class QuizController {
  constructor({ container, questionObjs }) {
    this._questionTrackEl = container;
    this._questions = questionObjs;

    this._currentQuestionIndex = -1;
    this._currentQuestion = null;
  }

  init() {
    // render q
    this._questions.forEach((q) => this._questionTrackEl.append(q.getView()));
    this.forward();
  }

  isFirstQuestion() {
    return this._currentQuestionIndex === 0;
  }

  isLastQuestion() {
    return this._currentQuestionIndex === this._questions.length - 1;
  }

  getCurrentQuestionIndex() {
    return this._currentQuestionIndex;
  }

  getNextIndex() {
    return this._questions[this._currentQuestionIndex + 1]
      ? this._currentQuestionIndex + 1
      : false;
  }

  getPreviousIndex() {
    return this._questions[this._currentQuestionIndex - 1]
      ? this._currentQuestionIndex - 1
      : false;
  }

  _syncIndexToQuestion() {
    this._currentQuestion = this._questions[this._currentQuestionIndex];
  }

  _adjustHeight() {
    setTimeout(() => {
      // needed to wait for next round of event loop
      this._questionTrackEl.style.height = `${this._currentQuestion.getHeight()}px`;
    });
  }

  forward() {
    const nextQuestionIndex = this.getNextIndex();
    if (nextQuestionIndex === false) return false;
    if (this._currentQuestion) {
      this._currentQuestion.pass();
    }
    this._currentQuestionIndex = nextQuestionIndex;
    this._syncIndexToQuestion();
    this._currentQuestion.appear();
    this._adjustHeight();
  }

  back() {
    const prevQuestionIndex = this.getPreviousIndex();
    console.log(prevQuestionIndex);
    if (prevQuestionIndex === false) return false;
    this._currentQuestion.hide();
    this._currentQuestionIndex = prevQuestionIndex;
    this._syncIndexToQuestion();
    this._currentQuestion.unpass();
    this._adjustHeight();
  }
}

export default QuizController;
