export const RADIO_QUESTION_TEMPLATE =
  document.querySelector("#radio-question").content.firstElementChild;

export const RADIO_OPTION_TEMPLATE = document.querySelector(
  "#radio-question-option"
).content.firstElementChild;

export const SLIDER_QUESTION_TEMPLATE =
  document.querySelector("#slider-question").content.firstElementChild;

export const THUMBS_QUESTION_TEMPLATE =
  document.querySelector("#thumbs-question").content.firstElementChild;

export const THUMBS_OPTION_TEMPLATE = document.querySelector(
  "#thumbs-question-option"
).content.firstElementChild;

export const SHOWCASE_SLIDE_TEMPLATE =
  document.querySelector("#showcase-slider").content.firstElementChild;
