import {
  RADIO_QUESTION_TEMPLATE,
  RADIO_OPTION_TEMPLATE,
} from "../utils/templates.js";
import Question from "./Question.js";

class RadioQuestion extends Question {
  constructor(question) {
    super(question);
  }

  _mapIndexToHex(index) {
    return ["#F2E554", "#9FF2F8", "#F5BCBB", "#DABAEE", "#CCFC9A", "#C9C9C9"][
      index
    ];
  }

  // OPTIMIZE: it may be helpful to provide this context
  // to the callback if the event needs to be captured
  onSelect(fn) {
    this._onSelect = fn;
  }

  /**
   * Hydrate radio question template
   */
  getView() {
    const questionEl = RADIO_QUESTION_TEMPLATE.cloneNode(true);

    // populate question text
    questionEl.querySelector(".question__heading").innerHTML = this._question;

    // add custom class to question container
    questionEl.classList.add(`question--id--${this._id}`);

    const optionListEl = questionEl.querySelector(".question__option-list");

    // if rows are not divisible by 3, order by 4
    if (this._options.length % 3) {
      optionListEl.classList.add("question__option-list--grid--4");
    }

    // populate options
    this._options.forEach(({ text, image, bubble_color: color, beers }, i) => {
      const optionEl = RADIO_OPTION_TEMPLATE.cloneNode(true);

      color = color || this._mapIndexToHex(i);

      // set bubble color
      optionEl.querySelector(
        ".question__option-bubble-outter"
      ).style.backgroundColor = color;
      optionEl.querySelector(".question__option-bubble").style.backgroundColor =
        color;

      const optionTextEl = optionEl.querySelector(".question__option-label");
      const optionInputEl = optionEl.querySelector("input");

      // set option text
      optionTextEl.innerHTML = text;

      // set input name
      optionInputEl.name = this._inputsName;
      // set input value
      optionInputEl.value = JSON.stringify(beers);

      if (this._onSelect) {
        optionInputEl.addEventListener("change", this._onSelect);
      }

      // NOTE: the input is nested inside the label, so no
      // id / for combo is needed.

      const imageEl = optionEl.querySelector(".question__image");

      if (image) {
        imageEl.src = image;
      } else {
        // TODO: add class that will be added when no image is being shown
        imageEl.remove();
        // optionTextEl.classList.add('.');
      }

      optionListEl.append(optionEl);
    });

    this._element = questionEl;

    return this._element;
  }
}

export default RadioQuestion;
