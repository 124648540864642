class Question {
  constructor({ id, type, question, options, color }) {
    this._id = id;
    this._type = type;
    this._question = question;
    this._options = options;
    this._color = color;

    this._inputsName = `question-${this._id}`;

    this._element = null;
  }

  getHeight() {
    return this._element.offsetHeight;
  }

  hide() {
    this._element.classList.remove("question--appear");
  }

  appear() {
    this._element.classList.add("question--appear");
  }

  pass() {
    this._element.classList.add("question--pass");
  }

  unpass() {
    this._element.classList.remove("question--pass");
  }
}

export default Question;
